// @flow
//Core
import React from 'react';
import { Box, Typography, Grid, Container } from '@material-ui/core';
import classNames from 'classnames';
//Local
import { RECRUIT_PAGE_STATIC_DATA, LANGUAGE } from '../../../constants';
import { previewStyles } from '../styles';
import LifeAtPaidy from '../LifeAtPaidy/LifeAtPaidy';

const PreviewSec = ({
  primaryText,
  secondaryText,
  primaryImage,
  secondaryImage,
  tetriaryImage1,
  tetriaryImage2,
  language = LANGUAGE.JA,
  isMobile = false,
}) => {
  const styles = previewStyles();

  const cultureData = RECRUIT_PAGE_STATIC_DATA[language].culture;

  return (
    <section className={styles.previewSec}>
      <Container maxWidth="xl" id="thirdSec">
        <div className={styles.previewTitle}>
          {language === LANGUAGE.JA ? 'Paidyのカルチャー' : 'Paidy Culture'}
        </div>
        <Box
          className={classNames(styles.gridContainer, styles.mobileFullWidth)}
        >
          <Box className={styles.primaryImage}>
            <img src={primaryImage} alt="" className={styles.img} />
          </Box>
          <Box className={styles.secondaryImage}>
            <img src={secondaryImage} alt="" className={styles.img} />
          </Box>
          <Box className={styles.tertriaryImage1}>
            <img src={tetriaryImage1} alt="" className={styles.img} />
          </Box>
          <Box className={styles.tertriaryImage2}>
            <img src={tetriaryImage2} alt="" className={styles.img} />
          </Box>
          <Box className={styles.primaryText}>
            <Typography variant="h4" color="secondary">
              {primaryText}
            </Typography>
          </Box>
          <Box className={styles.secondaryText}>
            <Typography variant="h4" color="primary">
              {secondaryText}
            </Typography>
          </Box>
          <Box className={styles.topRightText}>
            {cultureData.topRight.map(({ title, content }) => (
              <div key={title}>
                <div className={classNames(styles.textTitle, styles.textPink)}>
                  {title}
                </div>
                <div className={styles.textContent}>
                  {content.map((text) => (
                    <div key={text}>{text}</div>
                  ))}
                </div>
              </div>
            ))}
          </Box>
          <Box className={styles.btmLeftText}>
            <div className={styles.textTitle}>
              <span>{cultureData.btmLeft.titleP1}</span>
              <span className={styles.textPink}>
                {cultureData.btmLeft.titleP2}
              </span>
              <span>{cultureData.btmLeft.titleP3}</span>
            </div>
            <div
              className={classNames(styles.btmLeftColumns, {
                [styles.btmLeftColumnsMr]: language === LANGUAGE.EN,
              })}
            >
              <div>
                {cultureData.btmLeft.column1.map((content) => (
                  <div className={styles.textContent}>{content}</div>
                ))}
              </div>
              <div>
                {cultureData.btmLeft.column2.map((content) => (
                  <div className={styles.textContent}>{content}</div>
                ))}
              </div>
            </div>
          </Box>
        </Box>
      </Container>

      <LifeAtPaidy language={language} />

      <Container maxWidth="lg" className={styles.keywordsWrap}>
        <div className={styles.keywordsTitle}>{cultureData.keywords.title}</div>
        <Grid
          container
          spacing={isMobile ? 0 : 10}
          justify="center"
          alignItems="center"
        >
          {cultureData.keywords.content.map(({ text, image }) => (
            <Grid
              item
              md="auto"
              xs="4"
              key={text}
              className={styles.keywordItem}
            >
              <img src={image} alt={text} />
              <div>{text}</div>
            </Grid>
          ))}
        </Grid>
      </Container>
    </section>
  );
};

export default PreviewSec;
