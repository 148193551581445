/* eslint-disable import/prefer-default-export */
import { makeStyles } from '@material-ui/core/styles';

export const hrBlogStyles = makeStyles(() => ({
  subItemWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
  subItem: {
    '&:not(:last-child)': {
      borderBottom: '2px #B8A2A2 solid',
    },
    paddingBottom: '30px',
    marginBottom: '24px',
  },
  p: {
    fontSize: '14px',
    lineHeight: '24px',
    color: '#101825',
    marginBottom: '24px',
  },
  h3: {
    fontSize: '24px',
    lineHeight: '34px',
    fontWeight: '700',
  },
  hrBlogLink: {
    fontSize: '16px',
    lineHeight: '24px',
    textDecorationLine: 'underline',
    cursor: 'pointer',
    color: '#101825',
    marginTop: '46px',
  },
}));

export const itemsStyles = makeStyles(() => ({
  section: {
    paddingBottom: 115,
  },
  title: {
    textAlign: 'center',
    marginBottom: 60,
    paddingTop: 100,
    fontWeight: 900,
    color: '#1C1C1C',
  },
  subtitle: {
    paddingBottom: 20,
    marginBottom: 24,
    borderBottom: '1px solid #CFC4C5',
  },
  noBorderBottom: {
    borderBottom: 'none',
  },
  card: {
    minHeight: '340px!important',
  },
  tags: {
    marginRight: -16,
    marginBottom: 54,
  },
  tag: {
    marginBottom: 16,
    marginRight: 16,
  },
  activeTag: {
    backgroundColor: '#EBE6E2',
  },
  itemsWithBg: {
    position: 'relative',
    '&::before': {
      content: "' '",
      display: 'block',
      position: 'absolute',
      bottom: -20,
      left: 0,
      right: 0,
      width: '100%',
      height: 100,
      background:
        'linear-gradient(180deg, rgba(250, 250, 250, 0.0001) -74.5%, #FFF 41.44%)',
      zIndex: 1,
      filter: 'drop-shadow(-20px 15px 40px #fff)',
      boxShadow: '0 20px 5px #fff',
      pointerEvents: 'none',
    },
  },
  loadMoreBtn: {
    marginTop: '80px !important',
    padding: '0 81px !important',
    border: '1px solid #404042 !important',
    borderRadius: '28px',
    textTransform: 'none',
    color: '#404042',
  },
  '@media screen and (max-width: 1024px)': {
    title: {
      fontSize: 28,
      letterSpacing: '0.48px',
    },
    tag: {
      padding: '0 4px',
    },
    card: {
      marginBottom: 30,
    },
  },
}));

export const previewStyles = makeStyles(() => ({
  previewSec: {
    position: 'relative',
  },
  previewTitle: {
    fontSize: 36,
    lineHeight: '46px',
    fontWeight: 900,
    width: '100%',
    textAlign: 'center',
    paddingTop: 80,
    paddingBottom: 64,
  },
  subtitle: {
    textAlign: 'center',
    marginBottom: 70,
    marginTop: 32,
  },
  gridContainer: {
    display: 'grid',
    margin: '0 auto',
    gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr',
    gridTemplateRows: '256px 319px 319px 256px',
    gap: '0px 0px',
    gridTemplateAreas: `
      "primaryText primaryImage primaryImage topRightText  topRightText topRightText"
      "tertriaryImage1 primaryImage primaryImage topRightText  topRightText topRightText"
      "btmLeftText btmLeftText btmLeftText tertriaryImage2 secondaryImage secondaryImage"
      "btmLeftText btmLeftText btmLeftText secondaryText secondaryImage secondaryImage"
    `,
  },
  topRightText: {
    gridArea: 'topRightText',
    paddingLeft: 156,
    lineHeight: '26px',

    '&>div': {
      paddingBottom: 40,
    },
  },
  btmLeftText: {
    gridArea: 'btmLeftText',
    padding: '64px 72px 0 156px',
    lineHeight: '20px',

    '&>div': {
      paddingBottom: 40,
    },
  },
  btmLeftColumns: {
    fontSize: 14,
    display: 'flex',
    '&>div': {
      marginRight: 33,
    },
  },
  btmLeftColumnsMr: {
    '&>div': {
      marginRight: 63,
    },
  },
  textTitle: {
    fontSize: 24,
    lineHeight: '35px',
    paddingBottom: '14px',
    fontWeight: 500,
  },
  textPink: {
    color: '#E52581',
  },
  textContent: {
    color: '#1C1C1C',
  },
  primaryImage: {
    gridArea: 'primaryImage',
  },
  secondaryImage: {
    gridArea: 'secondaryImage',
  },
  tertriaryImage1: {
    gridArea: 'tertriaryImage1',
  },
  tertriaryImage2: {
    gridArea: 'tertriaryImage2',
  },
  primaryText: {
    gridArea: 'primaryText',
    padding: 26,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#CFC4C5',
    boxShadow: 'inset -10px 15px 20px rgba(81, 37, 37, 0.12)',
    textAlign: 'center',
    whiteSpace: 'pre',
    '& *': {
      lineHeight: '46px!important',
    },
  },
  secondaryText: {
    gridArea: 'secondaryText',
    padding: 21,
    maxWidth: 492,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    background: '#EBE6E2',
    '& *': {
      lineHeight: '46px!important',
    },
  },
  img: {
    objectFit: 'cover',
    width: '100%',
    height: '100%',
  },
  keywordsWrap: {
    marginTop: 193,
  },
  keywordsTitle: {
    fontWeight: 900,
    fontSize: 36,
    color: '#1C1C1C',
    marginBottom: 80,
    textAlign: 'center',
  },
  keywordItem: {
    textAlign: 'center',
    fontWeight: 700,
    color: '#404042',

    '& img': {
      marginBottom: 16,
    },
  },
  '@media screen and (max-width: 1024px)': {
    mobileFullWidth: {
      marginRight: -16,
      marginLeft: -16,
    },
    gridContainer: {
      gridTemplateColumns: '33% 33% 34%',
      gridTemplateRows: '133px 165px auto 133px 165px auto',
      gridTemplateAreas: `
      "primaryText primaryImage primaryImage"
      "tertriaryImage1 primaryImage primaryImage"
      "topRightText topRightText topRightText"
      "tertriaryImage2 secondaryImage secondaryImage"
      "secondaryText secondaryImage secondaryImage"
      "btmLeftText btmLeftText btmLeftText"
    `,
    },
    primaryText: {
      padding: '0 13px 0 12px',
      '& *': {
        lineHeight: '24px!important',
        fontSize: '12px !important',
        letterSpacing: '0.32px !important',
        color: '#ffffff !important',
      },
    },
    secondaryText: {
      padding: '0 11px',
      '& *': {
        lineHeight: '24px!important',
        fontSize: '12px !important',
        letterSpacing: '0.32px !important',
        color: '#393939 !important',
      },
    },
    topRightText: {
      padding: '24px 16px 48px 16px',
    },
    btmLeftText: {
      padding: '24px 16px 48px 16px',
    },
    keywordsWrap: {
      marginTop: 80,
    },
    keywordsTitle: {
      fontSize: 28,
    },
    keywordItem: {
      fontSize: 12,
      '& div': {
        padding: '12px 0 !important',
        whiteSpace: 'nowrap',
      },
    },
  },
  '@media screen and (max-width: 600px)': {
    keywordItem: {
      '& img': {
        width: '100%',
      },
    },
  },
}));
