import React from 'react';
import cx from 'classnames';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import Slider from 'react-slick';
import { PAIDY_HOST } from 'src/constants';
import styles from './PaidySlider.module.scss';

function NextArrow({ className, ...restProps }) {
  return <div className={cx(className, styles.nextArrow)} {...restProps} />;
}

function PrevArrow({ className, ...restProps }) {
  return <div className={cx(className, styles.prevArrow)} {...restProps} />;
}

const SETTINGS = {
  className: 'slider variable-width',
  centerPadding: '12px',
  slidesToShow: 3,
  swipe: true,
  dots: true,
  variableWidth: true,
  autoplay: true,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  dotsClass: styles.slickDots,
  appendDots: (dots) => (
    <div>
      <ul className={styles.dots}>{dots}</ul>
    </div>
  ),
  customPaging: () => <div className={styles.dot} />,
  responsive: [
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

const SLIDES = [
  {
    key: 1,
    // img: Img1,
    alt: 'Apple banner',
    to: `${PAIDY_HOST}/apple/`,
  },
  {
    key: 2,
    // img: Img3,
    to: `${PAIDY_HOST}/merchant/campaign/general_cashback_202212`,
    alt: 'cashback banner',
  },
  {
    key: 3,
    // img: Img2,
    to: 'https://www.amazon.co.jp/b?ie=UTF8&node=8215625051',
    alt: 'Amazon banner',
  },
  {
    key: 4,
    // img: Img4,
    to: `${PAIDY_HOST}/paidycard/`,
    alt: 'Paidycard banner',
  },
];

export default function PaidySlider({
  slides = SLIDES,
  renderItem,
  sectionClassname,
}) {
  return (
    <section className={cx(styles.section, sectionClassname)}>
      <div className={styles.container}>
        <Slider {...SETTINGS}>
          {slides.map((slide) =>
            renderItem ? (
              renderItem(slide)
            ) : (
              <OutboundLink
                key={slide.key}
                href={slide.to}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={slide.img}
                  alt={slide.alt}
                  width={240}
                  height={236}
                  className={styles.img}
                />
              </OutboundLink>
            )
          )}
        </Slider>
      </div>
    </section>
  );
}
