// @flow
//Core
import React, { useState, useEffect, useMemo } from 'react';
import get from 'lodash.get';
import uniqby from 'lodash.uniqby';
import { Container, Hidden } from '@material-ui/core';
import { graphql } from 'gatsby';
import { useSelector, useDispatch } from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';

//Local
import { NavigationSec } from 'src/components/mediaCenterSections/pressKit';
import {
  PreviewSec,
  JobsSec,
  Intro,
  Message,
  MessageMobile,
  HRSec,
} from '../../components/Recruit';
import { Layout, TopBanner2, TopBannerText } from '../../components';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import * as MediaCenterActions from '../../redux/mediaCenter/actions';

import banner from '../../images/hero-recruit.png';
import mobileBanner from '../../images/hero-recruit-mobile.png';
import firstSectionTertiaryImage1 from '../../images/firstSectionTertiaryImage1.jpeg';
import firstSectionTertiaryImage2 from '../../images/firstSectionTertiaryImage2.png';
import firstSectionPrimaryImage from '../../images/firstSectionPrimaryImage.jpeg';
import firstSectionSecondaryaryImage from '../../images/firstSectionSecondaryaryImage.jpeg';
import {
  SITE_METADATA,
  RECRUIT_PAGE_STATIC_DATA,
  LANGUAGE,
} from '../../constants';

export const query = graphql`
  {
    contentfulPageMediaCenterRecruitment(
      contentful_id: { ne: "5mCqMKbm5Zmj4OU6uhg9np" }
    ) {
      pageSubtitle
      pageTitle
      firstSectionPrimaryText
      firstSectionSecondaryText
      firstSectionPrimaryImage {
        file {
          url
        }
      }
      firstSectionSecondaryaryImage {
        file {
          url
        }
      }
      firstSectionTertiaryImage1 {
        file {
          url
        }
      }
      firstSectionTertiaryImage2 {
        file {
          url
        }
      }
      secondSectionSubtitle
      secondSectionTitle
      thirdSectionSubtitle
      thirdSectionTitle
      thirdSectionTags {
        title
        contentful_id
      }
    }
    allGreenhouseDepartment(
      filter: {
        childrenGreenhouseJobPost: { elemMatch: { active: { eq: true } } }
      }
    ) {
      nodes {
        id
        name
      }
    }
    allGreenhouseJobPost {
      nodes {
        id
        greenhouseId
        title
        created_at
        location {
          name
        }
        parent {
          ... on GreenhouseDepartment {
            id
            name
          }
        }
      }
    }
    allContentfulComponentArticle(
      filter: {
        blogSection: { contentful_id: { eq: "1w915a0HhAJPBs9eqSjl19" } }
      }
    ) {
      edges {
        node {
          contentful_id
          date
          blogSection {
            urlKey
          }
          previewImage {
            file {
              url
            }
          }
          title
          tags {
            title
            contentful_id
          }
        }
      }
    }
    site {
      siteMetadata {
        ogImage
        title
        twitterImage
        siteUrl
      }
    }
  }
`;

const Recruitment = ({ data, location }) => {
  const hrPage = useSelector((state) => state.mediaCenter.hrPage);
  const hrArticles = useSelector((state) => state.mediaCenter.hrArticles);
  const tag = decodeURIComponent(get(location, 'hash', '').replace('#', ''));
  const dispatch = useDispatch();
  const languageFromRoute = get(location, 'state.language', LANGUAGE.JA);
  const [language, setLanguage] = useState(languageFromRoute);
  const isMobile = useMediaQuery('(max-width:768px)');

  const handleLanguageChange = (lang) => {
    setLanguage(lang);
  };

  const navBlocks = useMemo(
    () => [
      {
        id: 'firstSec',
        title: language === LANGUAGE.JA ? 'はじめに' : 'Intro',
      },
      {
        id: 'secondSec',
        title:
          language === LANGUAGE.JA
            ? '創業者とCEOからのメッセージ'
            : 'Message from Founder & CEO',
      },
      {
        id: 'thirdSec',
        title: language === LANGUAGE.JA ? 'Paidyのカルチャー' : 'Paidy Culture',
      },
      {
        id: 'LifeAtPaidy',
        title: 'Life at Paidy',
      },
      {
        id: 'fourthSec',
        title: RECRUIT_PAGE_STATIC_DATA[language].job.title,
      },
      {
        id: 'fifthSec',
        title: RECRUIT_PAGE_STATIC_DATA[language].hr.title,
      },
      {
        id: '',
        title: language === LANGUAGE.JA ? 'English' : '日本語',
        onClick: () =>
          handleLanguageChange(
            language === LANGUAGE.JA ? LANGUAGE.EN : LANGUAGE.JA
          ),
      },
    ],
    [language]
  );

  useEffect(() => {
    dispatch(MediaCenterActions.getHrPageAsync());
    dispatch(MediaCenterActions.getHrArticlesAsync());
  }, [dispatch]);

  useEffect(() => {
    if (languageFromRoute !== language) {
      setLanguage(languageFromRoute);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [languageFromRoute]);

  return (
    <Layout
      SEOProps={{
        title: SITE_METADATA.recruit.title,
        description: SITE_METADATA.recruit.description,
      }}
      isRecruitPage
    >
      <TopBanner2
        bgImg={banner}
        textAlign="center"
        hasLanguageSelection
        mobileBgImg={mobileBanner}
        language={language}
        onLanguageChange={handleLanguageChange}
      >
        <TopBannerText>
          {RECRUIT_PAGE_STATIC_DATA[language].bannerText}
        </TopBannerText>
      </TopBanner2>
      <Breadcrumb
        customLevels={[
          {
            path: '/',
            title: 'トップ',
          },
          {
            path: '/recruit',
            title: language === LANGUAGE.JA ? '採用情報' : 'Careers',
          },
        ]}
      />
      <NavigationSec blocks={navBlocks} location={location} />
      <Intro language={language} />
      <Hidden lgUp>
        <MessageMobile language={language} />
      </Hidden>
      <Hidden smDown>
        <Message language={language} />
      </Hidden>
      <PreviewSec
        language={language}
        isMobile={isMobile}
        title={get(data, 'contentfulPageMediaCenterRecruitment.pageTitle')}
        subtitle={get(
          data,
          'contentfulPageMediaCenterRecruitment.pageSubtitle'
        )}
        primaryText={RECRUIT_PAGE_STATIC_DATA[language].culture.primaryText}
        secondaryText={RECRUIT_PAGE_STATIC_DATA[language].culture.secondaryText}
        primaryImage={firstSectionPrimaryImage}
        secondaryImage={firstSectionSecondaryaryImage}
        tetriaryImage1={firstSectionTertiaryImage1}
        tetriaryImage2={firstSectionTertiaryImage2}
      />
      <Container maxWidth="lg">
        <JobsSec
          title={RECRUIT_PAGE_STATIC_DATA[language].job.title}
          subtitle={get(
            data,
            'contentfulPageMediaCenterRecruitment.secondSectionSubtitle'
          )}
          tags={uniqby(get(data, 'allGreenhouseDepartment.nodes', []), 'id')}
          items={get(data, 'allGreenhouseJobPost.nodes', [])}
          selectedTag={tag}
          seeMoreText={RECRUIT_PAGE_STATIC_DATA[language].job.seeMore}
          isMobile={isMobile}
        />
      </Container>

      <HRSec language={language} data={{ hrPage, hrArticles }} />
    </Layout>
  );
};

export default Recruitment;
