// @flow
//Core
import React, { useEffect } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';

import classNames from 'classnames';
import get from 'lodash.get';
import { Link } from 'gatsby';

//Local
import typography from '../../../styles/typography.module.scss';
import { Card } from '../../../components';
import { itemsStyles, hrBlogStyles } from '../styles';
import * as MediaCenterActions from '../../../redux/mediaCenter/actions';

const HRBlog = () => {
  const styles = itemsStyles();
  const hrBlogStyle = hrBlogStyles();

  const hrPage = useSelector((state) => state.mediaCenter.hrPage);
  const hrArticles = useSelector((state) => state.mediaCenter.hrArticles);
  const dispatch = useDispatch();

  // Did mount
  useEffect(() => {
    dispatch(MediaCenterActions.getHrPageAsync());
    dispatch(MediaCenterActions.getHrArticlesAsync());
  }, [dispatch]);

  const itemJSX = (
    <Grid xs={12} md={7} item>
      <Card
        img={get(hrPage, 'primaryArticle.fields.previewImage.fields.file.url')}
        title={get(hrPage, 'primaryArticle.fields.title')}
        tags={get(hrPage, 'primaryArticle.fields.tags', []).map(
          (item) => item.fields.title
        )}
        link={`/media_center/${get(
          hrPage,
          'primaryArticle.fields.blogSection.fields.urlKey'
        )}/article/${get(hrPage, 'primaryArticle.sys.id')}`}
        variant="primary"
        theme="dark"
        size="md"
        className={styles.card}
        // date={formatDate('yyyy/MM/dd', new Date(get(hrPage, 'node.date')))}
      />
    </Grid>
  );

  const subItemsJSX = (
    <Grid xs={12} md={5} item>
      <div className={hrBlogStyle.subItemWrapper}>
        {hrArticles.slice(0, 3).map((item) => (
          <div key={item.fields.title} className={hrBlogStyle.subItem}>
            <Link
              to={`/media_center/${get(
                item,
                'blogSection.fields.urlKey'
              )}/article/${get(item, 'id')}`}
            >
              <p className={hrBlogStyle.p}>
                {get(item, 'fields.tags', [])
                  .map((tag) => tag.fields.title)
                  .join(', ')}
              </p>
              <h3 className={hrBlogStyle.h3}>{item.fields.title}</h3>
            </Link>
          </div>
        ))}
      </div>
    </Grid>
  );

  return (
    <section className={styles.section}>
      <Typography
        className={classNames(
          styles.subtitle,
          styles.noBorderBottom,
          typography.withDividerLeftPurple
        )}
        variant="h3"
      >
        HRからのお知らせ
      </Typography>
      <Grid container spacing={6}>
        {itemJSX}
        {subItemsJSX}
      </Grid>
      <Link to="/hr_blog">
        <p className={hrBlogStyle.hrBlogLink}>HRブログの全記事はこちら</p>
      </Link>
    </section>
  );
};

HRBlog.defaultProps = {};

export default HRBlog;
