// @flow
import React from 'react';
import classNames from 'classnames';
import get from 'lodash.get';

import { ItemsSec, PreviewSec } from '../../Column/News';
import { LANGUAGE, RECRUIT_PAGE_STATIC_DATA } from '../../../constants';
import columnStyles from '../../../styles/pages/column.module.scss';
import styles from './hrSec.module.scss';

export default ({ language = LANGUAGE.JA, data: { hrPage, hrArticles } }) => {
  const hrStaticData = RECRUIT_PAGE_STATIC_DATA[language].hr;

  return (
    <div id="fifthSec">
      <div className={styles.title}>{hrStaticData.title}</div>
      <PreviewSec
        title={get(hrPage, 'pageTitle')}
        continueReadingLabel={hrStaticData.continueReadingLabel}
        subtitle={get(hrPage, 'pageSubtitle')}
        card={{
          ...get(hrPage, 'primaryArticle.fields', {}),
          imgUrl: get(
            hrPage,
            'primaryArticle.fields.previewImage.fields.file.url'
          ),
          url: `/news/article/${get(hrPage, 'primaryArticle.sys.id')}`,
          tags: get(hrPage, 'primaryArticle.fields.tags', []).map(
            (item) => item.fields.title
          ),
        }}
      />
      <div className={classNames(columnStyles.container, styles.hrItems)}>
        <ItemsSec
          title={get(hrPage, 'secondSectionTitle')}
          tags={[]}
          items={hrArticles}
          hasLoadMore
          loadMoreBtnLabel={hrStaticData.seeMore}
          loadMoreBtnClassName={styles.loadMore}
          loadBy={3}
          redirectOnLoadmore
        />
      </div>
    </div>
  );
};
