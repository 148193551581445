// @flow
import React from 'react';
import classNames from 'classnames';

import { RECRUIT_PAGE_STATIC_DATA, LANGUAGE } from '../../../constants';
import founderImg from '../../../images/founder-picture.jpeg';
import ceoImg from '../../../images/ceo-picture.jpeg';
import styles from './Message.module.scss';

export default ({ language = LANGUAGE.JA }) => {
  const messageData = RECRUIT_PAGE_STATIC_DATA[language].message;

  return (
    <div id="secondSec" className={styles.mobileWrapper}>
      {/* Top message */}
      <div className={styles.mobileTitle}>
        {language === LANGUAGE.JA
          ? '創業者とCEOからのメッセージ'
          : 'Message from Founder & CEO'}
      </div>

      {/* founder */}
      <div className={styles.mobileMessage}>
        <div className={styles.mobileMessageTopBg}>
          <img src={founderImg} className={styles.img1} alt="Founder" />
        </div>
        <div className={styles.mobileMessageText}>
          {messageData.founder.preMessage && (
            <span className={styles.preMessage}>
              {messageData.founder.preMessage}
            </span>
          )}
          {messageData.founder.message}
          <div className={classNames(styles.mobileSign)}>
            <div>{messageData.founder.sign1}</div>
            <div>{messageData.founder.sign2}</div>
          </div>
        </div>
      </div>

      {/* ceo */}
      <div className={styles.mobileMessage}>
        <div className={styles.mobileMessageTopBg}>
          <img src={ceoImg} className={styles.img1} alt="CEO" />
        </div>
        <div className={styles.mobileMessageText}>
          {messageData.ceo.preMessage && (
            <span className={styles.preMessage}>
              {messageData.ceo.preMessage}
            </span>
          )}
          {messageData.ceo.message}
          <div className={classNames(styles.mobileSign)}>
            <div>{messageData.ceo.sign1}</div>
            <div>{messageData.ceo.sign2}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
