// @flow
import React from 'react';
import { Container } from '@material-ui/core';

import { SITE_METADATA, LANGUAGE } from '../../../constants';
import paidyLogo from '../../../images/logo-color.svg';
import styles from './Intro.module.scss';

const jaIntroText = () => (
  <div>
    <p>
      ペイディは「お買い物に『めんどくさい』はいらない」をミッションに生まれた
      <br />
      業界をリードする「あと払い（Buy Now Pay Later）」サービスです。
      <br />
      <br />
      信頼をもとに、社会に「信頼」を広め、
      <br />
      すべての人が<span>「夢に自信を持ち、心に余裕を持てる世界」</span>
      を作ることがペイディの使命です。
    </p>
  </div>
);

const enIntroText = () => (
  <div className={styles.enText}>
    <p>
      Paidy was born as an unique “Buy Now Pay Later” service
      <br />
      attacking “Mendokusai (hassle)” of shopping experience.
      <br />
      <br />
      Our mission is to spread trust around the world
      <br />
      and give people <span>room to dream.</span>
    </p>
  </div>
);

export default ({ language = LANGUAGE.JA }) => {
  const introTextJsx = language === LANGUAGE.JA ? jaIntroText() : enIntroText();

  return (
    <section className={styles.introWrapper} id="firstSec">
      <Container maxWidth="lg">
        <div className={styles.introBody}>
          <div className={styles.intro}>{introTextJsx}</div>

          <img
            alt={SITE_METADATA.recruit.title}
            src={paidyLogo}
            height="68"
            loading="lazy"
          />
        </div>
      </Container>
    </section>
  );
};
