// @flow
import React from 'react';
import { Container } from '@material-ui/core';
import classNames from 'classnames';

import { RECRUIT_PAGE_STATIC_DATA, LANGUAGE } from '../../../constants';
import founderImg from '../../../images/founder-picture.jpeg';
import ceoImg from '../../../images/ceo-picture.jpeg';
import styles from './Message.module.scss';

export default ({ language = LANGUAGE.JA }) => {
  const messageData = RECRUIT_PAGE_STATIC_DATA[language].message;

  return (
    <Container maxWidth="lg" id="secondSec">
      <section className={styles.wrapper}>
        <div className={styles.title}>
          {language === LANGUAGE.JA
            ? '創業者とCEOからのメッセージ'
            : 'Message from Founder & CEO'}
        </div>

        <div className={classNames(styles.messageWrapper)}>
          <div className={classNames(styles.message1, styles.message)}>
            <div>
              {messageData.founder.preMessage && (
                <span className={styles.preMessage}>
                  {messageData.founder.preMessage}
                </span>
              )}
              {messageData.founder.message}
            </div>
            <div className={classNames(styles.sign, styles.sign1)}>
              <div>{messageData.founder.sign1}</div>
              <div>{messageData.founder.sign2}</div>
            </div>
            <img src={founderImg} className={styles.img1} alt="Founder" />
          </div>
        </div>

        <div
          className={classNames(styles.messageWrapper, styles.messageWrapper2)}
        >
          <div className={classNames(styles.message2, styles.message)}>
            <div>
              {messageData.ceo.preMessage && (
                <span className={styles.preMessage}>
                  {messageData.ceo.preMessage}
                </span>
              )}
              {messageData.ceo.message}
            </div>
            <div className={classNames(styles.sign, styles.sign2)}>
              <div>{messageData.ceo.sign1}</div>
              <div>{messageData.ceo.sign2}</div>
            </div>
            <img src={ceoImg} className={styles.img2} alt="CEO" />
          </div>
        </div>
      </section>
    </Container>
  );
};
