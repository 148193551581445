import React, { useState } from 'react';
import VideoButton from 'src/components/Dream/VideoButton';
import VideoPlayer from 'src/components/Dream/VideoPlayer';
import PaidySlider from 'src/components/PaidySlider/PaidySlider';
import { LANGUAGE } from 'src/constants';
import styles from './LifeAtPaidy.module.scss';

const VIDEOS = [
  {
    key: 'Head of Marketing',
    title: (
      <>
        <b>Head of Marketing</b>
        <p>Marketing & Experience Division</p>
      </>
    ),
    url: 'https://www.youtube.com/embed/5Vwt7PnLl6o',
    thumbnail: 'https://img.youtube.com/vi/5Vwt7PnLl6o/maxresdefault.jpg',
  },
  {
    key: 'Head of Risk Engineering',
    title: (
      <>
        <b>Head of Risk Engineering</b>
        <p>Data & Risk Division</p>
      </>
    ),
    url: 'https://www.youtube.com/embed/lK7eaZ8r5Yw',
    thumbnail: 'https://img.youtube.com/vi/lK7eaZ8r5Yw/maxresdefault.jpg',
  },
  {
    key: 'Head of CFE & Platform & Infra Engineering',
    title: (
      <>
        <b>Head of CFE & Platform & Infra Engineering</b>
        <p>Engineering & IT Division</p>
      </>
    ),
    url: 'https://www.youtube.com/embed/AQtAW5S9G88',
    thumbnail: 'https://img.youtube.com/vi/AQtAW5S9G88/mqdefault.jpg',
  },
  {
    key: 'Head of Relationship Management',
    title: (
      <>
        <b>Head of Relationship Management</b>
        <p>Sales Division</p>
      </>
    ),
    url: 'https://www.youtube.com/embed/UyA6zBpeWRQ',
    thumbnail: 'https://img.youtube.com/vi/UyA6zBpeWRQ/maxresdefault.jpg',
  },
  {
    key: 'Head of PMO',
    title: (
      <>
        <b>Head of PMO</b>
        <p>Operations Division</p>
      </>
    ),
    url: 'https://www.youtube.com/embed/wMn7dYeMrVQ',
    thumbnail: require('src/images/head-of-pmo-thumbnail.png'),
  },
  {
    key: 'Head of Open Loop Product',
    title: (
      <>
        <b>Head of Open Loop Product</b>
        <p>Product Division</p>
      </>
    ),
    url: 'https://www.youtube.com/embed/E3THtyCJnfQ',
    thumbnail: 'https://img.youtube.com/vi/E3THtyCJnfQ/maxresdefault.jpg',
  },
  {
    key: 'HR Business Partner',
    title: (
      <>
        <b>HR Business Partner</b>
        <p>Corporate Division</p>
      </>
    ),
    url: 'https://www.youtube.com/embed/GYhRsFi8VXw',
    thumbnail: 'https://img.youtube.com/vi/GYhRsFi8VXw/maxresdefault.jpg',
  },
];

export default function LifeAtPaidy({ language = LANGUAGE.JP }) {
  const [videoUrl, setVideoUrl] = useState(null);

  const onClickVideo = (url) => () => setVideoUrl(url);

  return (
    <section className={styles.section} id="LifeAtPaidy">
      <h1 className={styles.title}>Life at Paidy</h1>
      <h2 className={styles.title2}>
        {language === LANGUAGE.JA
          ? 'Paidyで働く社員の生活をのぞいてみよう！'
          : 'Meet people who works for Paidy!'}
      </h2>
      <p className={styles.text}>
        {language === LANGUAGE.JA
          ? '社員のPaidyでの仕事内容から、プライベートまでをチェック。'
          : 'You can check how they work and what they do on their free time.'}
      </p>
      {videoUrl && (
        <VideoPlayer url={videoUrl} onClose={() => setVideoUrl(null)} />
      )}
      <PaidySlider
        sectionClassname={styles.slider}
        slides={VIDEOS}
        renderItem={(slide) => (
          <div key={slide.key} className={styles.slideItem}>
            <VideoButton
              width={280}
              height={256}
              background={slide.thumbnail}
              onClick={onClickVideo(slide.url)}
              className={styles.videoButton}
            />
            {slide.title}
          </div>
        )}
      />
    </section>
  );
}
